import React, { Component } from 'react'
import styled from 'styled-components'
import { Box, Title3, Large, colors } from 'js-components'
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper'
import { TestimonialGrid, TestimonialCard } from '../../components/TestimonialGrid'
import { Helmet } from 'react-helmet'
import { PageTitle, PageTitle2, PageTitle3 } from '../../components/PageTitle'
import Default from '../../components/Layouts/Default';

import IntelligentPhoneNumbers from '../images/intelligentPhoneNumbers.png'
import SfHalf from '../images/sfHalf.png'
import TeamDashHalf from '../images/teamDashHalf.svg'
import LibertyRing from '../animations/libertyRing.json'
import ActionEvent from '../images/actionEvent.svg';
import LiveWithCustomer from '../images/liveWithTheCustomer.svg';
import SalesforceGraph from '../images/salesforceGraph.png';



var lottie

export default class BetterCallCenter extends Component {
  constructor(props) {
    super(props)
    this.animationIsAttached = false
  }

  componentDidMount() {
    lottie = require('lottie-web')
    this.attachAnimation()
  }

  attachAnimation() {
    if (this.animationContainer !== undefined && !this.animationIsAttached) {
      const animationProperties = {
        container: this.animationContainer,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: LibertyRing,
      }

      lottie.loadAnimation(animationProperties)
    }
  }

  render() {
    return (
      <Default>
        <Helmet>
          <title>Truly | Call Center</title>
        </Helmet>

        <WrapperBackground
          color={colors.trulyDark}
          background='telephony1'
        >
          <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width="1">
              <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
              Call Center
              </PageTitle2>
              <PageTitle as="p" color={colors.white} mb="1.5rem" center>
              Serve Your Customers From Any Device, Anywhere In The World
              </PageTitle>
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperBackground center color={colors.trulyDark} background="managedByQ" gradientType="blueToGreen">
          <Section background={colors.trulyDark} flexDirection="column" maxWidth="100%">
            <Box>
                <PageTitle2 color={colors.white} mb="2rem" mt="2rem">
                Trusted By Category Defining Sales<br />& Customer Success Teams
              </PageTitle2>
            </Box>
            <TestimonialGrid>
              <TestimonialCard avatar="jackie" name="Jackie F" company="NewFront Insurance" quote="I love how you are able to listen to other colleagues calls while training. That live feature helped me significantly when I was training. I also like that you can see who is actively on a call."/>
              <TestimonialCard avatar="taylor" name="Taylor S" company="Cloud Kitchens" quote="Truly is great because it easily integrates with Salesforce to track my activity - both calls and texts. I also love the voicemail drop feature, as well as the mobile app that syncs flawlessly with my iPhone."/>
              <TestimonialCard avatar="billy" name="Billy B" company="Galvaninze" quote="I love how I can switch my number based on the purpose of my call and how easy Truly is to use. It's awesome I can listen to any of my colleagues phone calls at any time. It was a tremendous learning tool for me in my new job. The voicemail drop function is a game changer."/>
            </TestimonialGrid>
          </Section>
        </WrapperBackground>

        <WrapperSolid color='#292929'>
          <Section flexDirection={['column', 'row']} py={['3rem', '5rem']}>
            <Box width={[1/2, 1/2]} mb={['16pt', '0pt']}>
              <CenteredImg src={SfHalf} alt="Salesforce"/>
            </Box>
            <Box width={[1, 1/2]}>
              <Title3 as="h2" color={colors.accentFog} bold mb="1.5rem">
              ALL THE FEATURES YOU NEED
              </Title3>
              <Large as="p" color={colors.white} mb="1.5rem">
                IVR, custom greetings, phone menus, business hours, hold music, ACD (cascading ring groups, simulring, round robin) screenpops, callback capability and much more.
              </Large>

            </Box>
          </Section>
        </WrapperSolid>


        <WrapperSolid
          color={colors.white}
        >
          <Section flexDirection={['column', 'row']} py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width={[1, 1/2]}>
              <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem">
              INTEGRATED CONVERSATION INTELLIGENCE & AUTOMATION
              </Title3>
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                Truly's integrated Conversation Intelligence automatically records, transcribes and analyzes your reps sales calls, making all of their conversation data searchable and reportable in Salesforce.
                <br/><br/>
               Using our Revenue Process Automation platform, you can then use this data to automate most of your sales coaching and data entry workflows, whether it's validating script compliance, updating dispositions or creating follow up tasks.
              </Large>
            </Box>
            <Box width={[1/2, 1/2]} p="32pt 0 32pt 32pt">
              <CenteredImg src={ActionEvent} alt="Set Disposition"/>
            </Box>
          </Section>
        </WrapperSolid>


        <WrapperSolid color='#292929'>
          <Section flexDirection={['column', 'row']} py={['3rem', '5rem']}>
            <Box width={[1/2, 1/2]} mb={['16pt', '0pt']}>
              <CenteredImg src={LiveWithCustomer} alt="Salesforce"/>
            </Box>
            <Box width={[1, 1/2]}>
              <Title3 as="h2" color={colors.accentFog} bold mb="1.5rem">
              THE RIGHT PERSON & THE RIGHT INFORMATION AT THE RIGHT TIME
              </Title3>
              <Large as="p" color={colors.white} mb="1.5rem">
              Intelligently route calls to the right reps. Determine what they see when the phone rings and after a call is answered.  Automatically connect Tickets and Activities to the right customer record to make cases digestible, shareable and more easily reportable.
              </Large>

            </Box>
          </Section>
        </WrapperSolid>


        <WrapperSolid>
          <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '0rem']}>
            <Box width={[1, 1/2]}>
              <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
              GORGEOUS VISUALIZATIONS
              </Title3>
              <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
              Truly's delivers the most advanced reporting in the market, through unmatched integrations with your CRM and powerful data analysis using AI, transcription, sentiment analysis and more.
              </Large>
            </Box>
            <Box width={[1/2, 1/2]} pb="2rem">
              <CenteredImg src={SalesforceGraph} alt="Team Dashboard"/>
            </Box>
          </Section>
        </WrapperSolid>
      </Default>
    )
  }
}

const CenteredImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`
